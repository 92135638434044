const CONFIG = {
    'api_url': '', // 废弃
    'ga': 'G-XXXXXX',
    'whiteboard_id': '82f86409b589ee493fb13337a699cf1abaa705db74f059fd9fecdfde34d0ee13',
    'title': '绘素的数字花园', // 站点标题
    'pages': {
        'Activity': 'activity', // 花园活跃状态页面
        'About': '652c855a-fa49-4624-97ea-634b9bcf3b2a'
    }
}

export default CONFIG
